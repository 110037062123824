.footer-container {
    position: relative;
}

.footer-container>hr {
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    justify-content: center;
    height: 20rem;
}

.social-links {
    display: flex;
    gap: 4rem;
    
}

.social-links>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-footer>img {
     width: 10rem;
}

